import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BiPrinter } from 'react-icons/bi'
import { api } from '../../../../api'

const CurrentAbonnement = () => {

    const [infoPrest, setInfoPrest] = useState(null);
    const [infoAbo, setInfoAbo] = useState(null);
    const idPrest = 5; //Soloina localStorage

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);

        return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })

    }

    useEffect(() => {
        // Fetch customer details
        axios.get(`${api}/api/getInfoClient/${idPrest}`)
          .then(response => {
            setInfoPrest(response.data);
          })
          .catch(error => {
            console.error("There was an error fetching the customer data!", error);
          })

          axios.get(`${api}/api/getAboUserConf/${idPrest}`)
          .then(response => {
            setInfoAbo(response.data);
          })
          .catch(error => {
            console.error("There was an error fetching the customer data!", error);
          })
    
      }, [])

  return (
    <> <div className="flex justify-between items-start">
            <h4 className='font-semibold text-xl '>Information sur mon abonnment actuel</h4>
            <BiPrinter className='w-9 h-9 hover:bg-slate-50 rounded-md p-2 cursor-pointer text-gray-700'/>
        </div>
       <div className="border-line">
       
        <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
                <div className='bg-primary text-white font-semibold p-2 rounded-sm'>
                    Information personnelle
                </div>
            <table  className='table1'>
                <tbody>
                  <tr>
                    <td>Nom</td>

                    <td>{infoPrest?.nom}</td>

                </tr>  
                <tr>
                    <td>Contact</td>
                    <td>{infoPrest?.contact}</td>
                </tr>  
                <tr>
                    <td>Adresse</td>

                    <td>{infoPrest?.adresse}</td>

                </tr>  
                </tbody>
            </table></div>
            <div>
                <div className='bg-primary text-white font-semibold p-2 rounded-sm'>

                    Fournisseur

                </div>
            <table className='table1'>
                <tbody>
                  <tr>
                    <td>Nom</td>
                    <td>HAISOA Consulting</td>
                </tr>  
                <tr>
                    <td>Contact</td>
                    <td>+261 34 14 256 78</td>
                </tr>  
                <tr>
                    <td>Adresse</td>
                    <td>Mahazoarivo</td>
                </tr>   
                </tbody>
            </table>    
            </div>
            
        </div>
        <div className="bg-gray-50 rounded-md px-4 py-3 flex gap-4 mb-4 text-sm font-medium">
            <div className="fex-col space-y-1">

                <div>Date début</div>
                <div className=' p-2 bg-green-200 text-green-600 rounded-md'>{formatDate(infoAbo?.dateDebut)}</div>
            </div>
            <div className="fex-col space-y-1">
                <div>Date fin</div>
                <div  className=' p-2 bg-red-200 text-red-600 rounded-md'>{formatDate(infoAbo?.dateFin)}</div>

            </div>
        </div>

        <div className="overflow-hidden mb-4">
            <table className='table2'>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Réference</th>
                        <th>Quantité</th>
                        <th>Total</th>
                    </tr>
                    
                </thead>
                <tbody>
                    <tr>
                        <td>Abonnement {infoAbo?.type}</td>
                        <td>{infoAbo?.ref}</td>
                        <td>1</td>
                        <td>{infoAbo?.prix}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="flex justify-end">
            <div className="grid grid-cols-2 gap-x-32 gap-y-2">
                <ul className="flex flex-col items-start  gap-y-1">
                    <li>Sous-total</li>
                    {/* <li>Tax (20%)</li> */}
                    <li>Remise (%)</li>
                    <li className='text-md font-medium'>Total</li>
                </ul>
                <ul className="flex flex-col items-end gap-y-1">

                    <li>{infoAbo?.prix}</li>
                    <li>200</li>

                    <li>0</li>
                    <li className='text-md font-medium'>2200</li>
                </ul>
            </div>
        </div>
        </div> 
    </>
  )
}

export default CurrentAbonnement