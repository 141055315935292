import React,{useState, useEffect} from 'react'
import Button from '../../../components/Button'
import axios from 'axios'
import { api } from '../../../../api'

const DemandeAbonnement = () => {

    const id = 5 //Soloina localStorage, id du prestataire connecté
    const [abonnement, setabonnement] = useState([
        {
            type: 'Plan standard',
            prix: 50000,
        },
        {
            type: 'Plan basique',
            prix: 70000,
        },
        {
            type: 'Plan professionnel',
            prix: 100000,
        },
        {
            type: 'Plan premium',
            prix: 200000,
        }
        
    ])
    const [formData, setFormData] = useState({
        type: 'Plan basique',
        prix: '',
        objet: '',
        description: '',
        image:'',
        ref: null
    });
    
    const handleChange = (e) => {
        // const { name, value } = e.target;
        // setFormData({
        //     ...formData,
        //     [name]: value
        // });

        const { name, value, files } = e.target;
        if (name === "image" && files && files.length > 0) {
            setFormData({
                ...formData,
                [name]: files[0], // Stocker le fichier directement
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    }

    const handleSubmit = async () => {
        // console.log(formData)
        const forms = new FormData();
        forms.append('type', formData.type);
        forms.append('prix', formData.prix);
        forms.append('objet', formData.objet);
        forms.append('description', formData.description);
        forms.append('ref', formData.ref);

        if (formData.image) {
            forms.append('image', formData.image);
        }
        try {
            const response = await axios.post(api + `/api/ajoutAbo/${id}`, forms);
            console.log('Abonnement enregistré');
            // Gérer la réponse comme l'affichage d'un message de succès
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la demande:', error);
            // Gérer l'erreur comme l'affichage d'un message d'erreur
        }
    }
    
  return (
    <div>
        <div className="grid  gap-4">
            <div className='col-span-'>
                <h5 className='title1 mb-1'>Envoyer une demande</h5>
                <div className="border-line mb-4">
                    <div className="grid grid-cols-3 gap-y-4 text-sm">
                        <label htmlFor="" className='col-span-1'>Type d'abonnement</label>
                        <div className="col-span-2">

           <select 
                        type="text" 
                        name="type" 
                        value={formData.type} 
                        onChange={handleChange} 
                        // placeholder='Standard' 
                        className='w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline focus:outline-2 focus:outline-blue-500/20'
        >
            {abonnement.map((item, index)=>
            { return <option key={index} value={item.type}>{item.type}</option> }
                
            )}
            
           
        </select>
        </div>

        <label htmlFor="" className='col-span-1'>Prix d'abonnement (Ar)</label>
        <div className="col-span-2">
        <input 
            type="number" 
            name="prix" 
            value={abonnement.find(item => item.type === formData.type).prix} 
            onChange={handleChange} 
            placeholder='50000' 
            className='w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline focus:outline-2 focus:outline-blue-500/20'
        /></div>

        <label htmlFor="" className='col-span-1'>Objet</label>
        <div className="col-span-2">
        <input 
            type="text" 
            name="objet" 
            value={formData.objet} 
            onChange={handleChange} 
            placeholder='Demande de renouvellement' 
            className='w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline focus:outline-2 focus:outline-blue-500/20'
        /></div>

        <label htmlFor="" className='col-span-1'>Description ou message</label>
        <div className="col-span-2">
        <textarea 
            name="description" 
            value={formData.description} 
            onChange={handleChange} 
            placeholder='Ecrire ici votre message' 
            className='w-full h-[120px] border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline focus:outline-2 focus:outline-blue-500/20'
        /> </div>

    <label htmlFor="" className='col-span-1'>Pièce jointe</label>
            <div className="col-span-2">
            <input 
                type="file" 
                name="image" 
                onChange={handleChange} 
                placeholder='example@gmail.com' 
                className='w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline focus:outline-2 focus:outline-blue-500/20'
            /></div>
            <label htmlFor="" className='col-span-1'>Référence de paiement</label>
        <div className="col-span-2">
        <input 
            type="number" 
            name="ref" 
            value={formData.ref} 
            onChange={handleChange} 
            placeholder='87654321' 
            className='w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline focus:outline-2 focus:outline-blue-500/20'
        /></div>

                    </div>
            </div>
            <div className="flex justify-end px-6">
    <Button onClick={handleSubmit}>Enregistrer</Button>
</div>

            
        </div>
        {/* <div className="col-span-2 border-line">
                <h5 className='title1 pb-2 border-b mb-4'>Historique des demandes</h5>

                <div className="py-2 border-b gap-y-2 flex-col flex">
                    <p className='title2'>#13544-Alerte sur une panne</p>
                    <div className="flex items-center justify-between">
                        <p className='text-xs text-gray-1'>Ouvert</p>
                        <p className='text-xs text-gray-1'> il y a 2 heures</p>
                    </div>
                </div>

                 <div className="py-2 border-b gap-y-2 flex-col flex">
                    <p className='title2'>#13544-Alerte sur une panne</p>
                    <div className="flex items-center justify-between">
                        <p className='text-xs text-gray-1'>Ouvert</p>
                        <p className='text-xs text-gray-1'> il y a 2 heures</p>
                    </div>
                </div>

                 <div className="py-2 border-b gap-y-2 flex-col flex">
                    <p className='title2'>#13544-Alerte sur une panne</p>
                    <div className="flex items-center justify-between">
                        <p className='text-xs text-gray-1'>Ouvert</p>
                        <p className='text-xs text-gray-1'> il y a 2 heures</p>
                    </div>
                </div>

                 <div className="py-2 border-b gap-y-2 flex-col flex">
                    <p className='title2'>#13544-Alerte sur une panne</p>
                    <div className="flex items-center justify-between">
                        <p className='text-xs text-gray-1'>Ouvert</p>
                        <p className='text-xs text-gray-1'> il y a 2 heures</p>
                    </div>
                </div>
                
            </div> */}
    </div>
</div>
  )
}

export default DemandeAbonnement