import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api } from '../../../api';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button'
import Feedback from '../../../admin/components/feedback'
import ConfirmModal from '../../components/Modal/ConfirmModal'

const AvalaibilityDay = ({ openingDays, id }) => {
  // const { id } = useParams();
  const [idSelected, setIdSelected] = useState(0)
  const [disponibilite, setDisponibilite] = useState(
        {
        lundi: 0,
        mardi: 0,
        mercredi: 0,
        jeudi: 0,
        vendredi: 0,
        samedi: 0,
        dimanche: 0,
      }
  )

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState({
      success: false,
      fails: false
  })

  const handleCheckboxChange = (jour) => {
    setDisponibilite((prevDisponibilite) => ({
      ...prevDisponibilite,
      [jour]: !prevDisponibilite[jour],
    }));
  }

  const handleSave = () => {
    const form = new FormData()
    form.append('lundi', disponibilite.lundi)
    form.append('mardi', disponibilite.mardi)
    form.append('mercredi', disponibilite.mercredi)
    form.append('vendredi', disponibilite.vendredi)
    form.append('samedi', disponibilite.samedi)
    form.append('dimanche', disponibilite.dimanche)
    form.append('jeudi', disponibilite.jeudi)
    form.forEach((el, index)=>console.log(index +" "+el))
    console.log(idSelected ? "modification ":"Ajout "+"Jour ouvranble: "+form+" idService"+id)
    console.log("Avalaibility day: "+disponibilite.lundi+" Id sélectionné:"+idSelected)
    const request = idSelected ? 
      axios.post(api + `/api/modJourOuvrable/${id}`, disponibilite) : 
      axios.post(api + `/api/ajoutJourOuvrable/${id}`, disponibilite) 

    request
      .then((response) => {
        console.log('Enregistré avec succès:', response)
        setIsOpen({ ...isOpen, success: true })
      })
      .catch((error) => {
        console.error('Erreur lors de l\'enregistrement:', error)
        setIsOpen({ ...isOpen, fails: true })
      })
  }

  useEffect(() => {
    if (!idSelected) {
      axios.get(api + `/api/jourOuvrableService/${id}`)
        .then((response) => {
          if(response.data.length !== 0){
            setDisponibilite(response.data[0])
            setIdSelected(response.data[0].id)
          }
          // setIdSelected(response.data.id)
            console.log("Avalaibility day: "+response.data+" Id sélectionné:"+idSelected)
        })
        .catch((error) => console.error('Erreur lors de la récupération des jours ouvrables:', error));
    }
  }, [])

  const joursDeLaSemaine = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];

  return (
    <div className="border-line">
      <div className="flex justify-between items-center pb-4 mb-4"> 
        <p className="title1">Jour d'ouverture</p>
        <Button onClick={()=>showConfirmModal(true)}>
          Enregistrer
        </Button>
      </div>
      <div className="font-medium text-sm">
        {Object.keys(disponibilite)
        .filter(jour => joursDeLaSemaine.includes(jour.toLowerCase()))
        .map((jour, index) => (
          <div key={index} className="flex justify-between items-center border-b py-4">
            <div className="flex items-center gap-1">
              <input 
                type="checkbox" 
                name={jour} 
                id={jour} 
                checked={disponibilite[jour] || false}
                onChange={() => handleCheckboxChange(jour)}
              />
              <label htmlFor={jour}>{jour.charAt(0).toUpperCase() + jour.slice(1)}</label>
            </div>
          </div>
        ))}
      </div>

          <ConfirmModal 
                show={showConfirmModal} 
                onClose={() => setShowConfirmModal(false)}
                title="Confirmation"
                text="Êtes-vous sûr de vouloir enregistrer cette offre modifiée ?"
                buttonText="Enregistrer"
                action={handleSave}
            />

            {/* Feedback success or error */}
            <Feedback isOpen={isOpen.success} onClose={() => setIsOpen({ ...isOpen, success: false })} message='Modification réussie !' />
            <Feedback isOpen={isOpen.fails} onClose={() => setIsOpen({ ...isOpen, fails: false })} type='error' message='Une erreur est survenue !' />
    </div>
  );
};

export default AvalaibilityDay;
