import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header';
import Sidebar from '../components/sidebar';
import '../index.css'
import axios from 'axios';
import { api } from '../../api';
export default function LayoutAdmin() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const [title,setTitle]=useState("Bienvenu")
    const [dataAdd,setDataAdd]=useState()
    const [all,setAll]=useState({
        codeGenerated:''
    })
    const [myData,setMyData]=useState({
       
    })
    function fetchMyData() {
        try {
          const id = localStorage.getItem('soro-id');
          axios.get(api + `/api/getInfoClient/${id}`)
            .then(res => {
                setMyData(res.data)
            })
            .catch(err => {
              console.error(err);
            })
        } catch (error) {
          console.error(error)
        }
      }
    useEffect(() => {
        // console.log(mediaQuery)
        fetchMyData()
    }, [])
    return (
        <div className='w-screen admin overflow-x-hidden  bg-slate-50 flex flex-row items-start'>
            <div className='lg:w-[250px]  max-lg:hidden max-md:w-full bg-white h-screen overflow-y-hidden'>
                <Sidebar />
            </div>
            <div className="w-full flex flex-row justify-center items-start ">
                <div className='flex flex-col justify-start items-center w-11/12'>
                    <Header />
                    <Outlet  context={{myData,setMyData,fetchMyData,all,setAll}}/>
                </div>
            </div>

        </div>
    )
}
