import React, {useState} from 'react'
import Dropdown from '../Dropdown'
// import bars from "../../../website/assets/icons/bars-svgrepo-com.svg"
// import ResponsiveSidebar from "./ResponsiveSidebar"
import Logo from "../../../website/assets/logo/logo.png"
import ButtonDropdown from '../Dropdown/ButtonDropdown'
// import Logo from "../../assets/icons/logo.png"
import Notification from "../Notification"
import bars from "../../../website/assets/icons/bars-svgrepo-com.svg"

import ResponsiveMenu from "../Sidebar/ResponsiveMenu"

const Index = ({title}) => {
    const [searchKey, setSearchKey] = useState(null)
    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    }

  return (
    <div className='flex justify-between w-full items-center mb-6  bg-white lg:bg-transparent fixed top-0 left-0 lg:static md:my-3 z-50 py-3 md:shadow-none shadow-md'>
  
  <div className='flex justify-between items-center w-full px-4'>
    {/* <div className='flex items-center gap-6'> */}
      {/* <div className="relative mt-1">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
              <path d="M10.036 8.278 19.294.488A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
              <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
            </svg>
          </div>
          <input
            type="text"
            id="password"
            name="password"
            value={searchKey}
            onChange={(e)=>setSearchKey(e.target.value )}
            className="w-[400px] border text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Rechercher..."
            required
          />
      </div> */}
      {/* <div> */}
      <h1 className='md:block hidden text-lg font-medium'>{title}</h1>
     
      <div className='hidden lg:flex gap-4 items-center'><Notification/><Dropdown/>  </div>
      <img src={Logo} alt="" className="h-10 w-20 lg:hidden block" />
      
      {/* <img src={Logo} alt="" className='w-12 h-12 md:hidden block cursor-pointer' onClick={()=>setShowMenu(true)}/> */}
      <div className="md:hidden block">
                {showMenu ? (
                  <span
                    onClick={toggleMenu}
                    className=" cursor-pointer transition-all"
                    size={30}
                    >
                      <img src={bars} alt="" className="w-10 h-10"/>
                    </span>
                ) : (
                  <span
                    onClick={toggleMenu}
                    className="cursor-pointer transition-all"
                    size={30}
                  >
                    <img src={bars} alt="" className="w-10 h-10"/>
                  </span>
                )}
              </div>
      </div>
        <ResponsiveMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      
    </div>

  )
}

export default Index