import { Box, Drawer, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IoMdMenu } from 'react-icons/io'
import { IoChevronForward, IoHome, IoLogOut, IoMenu, IoMenuSharp, IoNotifications, IoNotificationsOutline, IoPerson, IoPersonCircleOutline } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../assets/image/logo.png';
import axios from 'axios'
import { api } from '../../../api'
import { FaIdCard } from 'react-icons/fa6'
export default function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const id = 1
  const [data, setData] = useState({})
  function fetchMyData() {
    try {
      // const id = localStorage.getItem('soro-id');
      axios.get(api + `/api/getInfoClient/${id}`)
        .then(res => {
          setData(res.data)
        })
        .catch(err => {
          console.error(err);
        })
    } catch (error) {
      console.error(error)
    }
  }
  function logout() {
    localStorage.removeItem('soro-id');
    localStorage.removeItem('soro-photo');
    localStorage.removeItem('soro-email');
    localStorage.removeItem('soro-role');
    navigate('/login');


  }
  useEffect(() => {
    // console.log(location.pathname)
    fetchMyData()
  }, [])
  const drawerItems = [
    { label: 'Tableau de bord', icon: <IoHome />, path: '/admin' },
    { label: 'Client', icon: <IoPerson />, path: '/admin/client' },
    { label: 'Prestataire', icon: <IoPerson />, path: '/admin/prestataire' },
    { label: 'Administrateur', icon: <IoPerson />, path: '/admin/administrateur' },
    { label: 'Abonnement', icon: <IoPersonCircleOutline />, path: '/admin/abonnement/0' },
    { label: 'Secteur', icon: <IoMenuSharp />, path: '/admin/secteur' },
    { label: 'Service', icon: <IoPersonCircleOutline />, path: '/admin/service/0' },
    { label: 'Profil', icon: <FaIdCard />, path: '/admin/profile' },
    { label: 'Notification', icon: <IoNotificationsOutline />, path: '/admin/notification' },
    { label: 'Settings', icon: <IoMenu />, path: '/settings' },

  ]

  return (
    <div className='w-full flex flex-row justify-between items-center p-2'>
      <div className="flex gap-x-4 flex-row justify-start items-center">
        <button className='lg:hidden' onClick={() => { setIsOpen(true) }}>
          <IoMdMenu className='text-lg' />
        </button>
        <div className='flex flex-col items-start '>
          <span>Bonjour, {data.nom}</span>
          <span className='font-bold text-xl'>Bienvenu</span>
        </div>
      </div>
      <div className='flex flex-row gap-x-2 justify-end items-center '>
        {/* <IoNotificationsOutline /> */}
        <div className='flex flex-row gap-x-2 justify-end items-center' onClick={() => {
          navigate('/admin/profile')
        }}>
          {
            data.photo ? (
              <img src={api + '/storage/' + data.photo} className='w-8 h-8 rounded-full' />
            ) : (
              <IoPersonCircleOutline />
            )
          }
          <span>
            {data.nom}
          </span>
        </div>
      </div>
      <Drawer anchor='left' open={isOpen} onClose={() => { setIsOpen(false) }}>
        <Box sx={{ width: 250, backgourndColor: 'white' }}>
          <List>
            <ListItem>
              <img src={logo} className="w-[138px] " />
            </ListItem>
            {drawerItems.map((item, index) => (
              <ListItemButton button key={index}
                sx={{
                  // backgroundColor:  '',
                  borderRight: location.pathname === item.path ? '4px solid #FF7200' : 'none',
                  color: location.pathname === item.path ? '#FF7200' : '',

                  px: 2,
                  gap: 1,
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  py: 1,
                }}
                style={location.pathname === item.path ? { backgroundColor: '#FFF9EF' } : {}}
                selected={location.pathname === item.path}

                onClick={() => { setIsOpen(false); navigate(item.path) }}>
                <ListItemIcon sx={{
                  color: location.pathname === item.path ? '#FF7200' : '',
                }} onClick={() => { item?.onClick() }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            ))}
            <ListItemButton button 
                sx={{
                  // backgroundColor:  '',
                 
                  px: 2,
                  gap: 1,
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  py: 1,
                }}
                
                onClick={() => { logout() }}>
                <ListItemIcon sx={{
                  
                }} onClick={() => { logout() }}><IoLogOut/></ListItemIcon>
                <ListItemText primary={"Se déconnecter"} />
              </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </div>
  )
}