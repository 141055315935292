
import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import Input from '../../../../components/form/input'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import axios from 'axios'
import { api } from '../../../../../api'
import Feedback from '../../../../components/feedback'
import Modals from '../../../../components/modal'
import Load from '../../../../components/load'
import GoBack from '../../../../components/btn/goBack'

export default function UpdateService() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState({
        id: '',
        nom: '',
        adresse: '',
        categorie: '',
        description: '',
        // image: null
    })
    // const [categorie,setCategorie]=useState()
    const [categories, setCategories] = useState([
        {
            id: 1,
            nom: 'Catégorie 1'
        },
        {
            id: 2,
            nom: 'Catégorie 2'
        },
        // Add more categories here
    ])
    const [options, setOptions] = useState({
        loading: false,
        disabled: false
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })
    function handleSubmit(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        // update the service here
        // navigate(`/admin/services/${id}`)
        try {
            const form = new FormData()
            form.append('id', id)
            form.append('nom', data.nom)
            form.append('adresse', data.adresse)
            form.append('type', data.type)
            form.append('description', data.description)
            // form.append('image',data.image)

            axios.post(api + '/api/route', form)
                .then(() => {
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, success: true })
                    navigate(`/admin/services/${id}`)
                })
                .catch((error) => {
                    console.error(error)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, fails: true })
                })

        } catch (error) {
            console.error(error)
            setOptions({ ...options, loading: false })
            setIsOpen({ ...isOpen, fails: true })

        }

    }
    function fetchData() {
        try {
            axios.get(api + '/api/route/' + id)
                .then(response => setData(response.data))
                .catch(error => console.error(error))
        } catch (error) {
            console.error(error)
        }
    }
    function fetchCategories() {
        try {
            axios.get(api + '/api/route')
                .then(response => setCategories(response.data))
                .catch(error => console.error(error))
        } catch (error) {
            console.error(error)

        }
    }
    useEffect(() => {
        fetchData()
        fetchCategories()
    }, [])
    useEffect(() => {
        if (
            data.nom !== '' &&
            data.adresse !== '' &&
            data.categorie !== '' &&
            data.description !== ''
        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }

    }, [data])

    return (
        <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex justify-center h-screen'>
            <div className='w-full'>
                <Card>
                    <div className="flex flex-row justify-start items-center gap-x-4">
                        <GoBack />
                        <h4>Modification service</h4>

                    </div>
                    <div className="grid grid-cols-3 w-full gap-3">
                        <Input label={"Nom"} required={true} value={data.nom} onChange={(e) => { setData({ ...data, nom: e.target.value }) }} />
                        <Input label={"Adresse"} required={true} value={data.adresse} onChange={(e) => { setData({ ...data, adresse: e.target.value }) }} />
                        <div className='flex flex-col justify-start items-start gap-y-1 w-full' >
                            <label className='text-slate-500'>Categorie</label>
                            <select value={data.categorie} onChange={(e) => { setData({ ...data, categorie: e.target.value }) }} className={` w-full border-[#DDDDDD] border  rounded-[4px] h-10 p-1`}>
                                {
                                    categories.map((categorie) => {
                                        return (
                                            <option key={categorie.id} value={categorie.id}>{categorie.nom}</option>
                                        )
                                    })
                                }

                            </select>
                        </div>
                    </div>
                    <Input label={"Description"} required={true} value={data.description} onChange={(e) => { setData({ ...data, description: e.target.value }) }} />
                    <div
                        className="flex flex-row justify-end items-center gap-x-3 mt-3"
                    >
                        <div className='flex flex-row gap-x-3'>
                            <Button type="button" variant="outlined" fullWidth onClick={() => { navigate(-1) }}>
                                Annuler
                            </Button>
                            <Button type="submit" variant="contained" fullWidth disabled={options.disabled}>
                                <Load title='Modifier' loading={options.loading} />
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
            <Feedback type='success' message='Modifiée avec succès.' isOpen={isOpen.success} onClose={() => {
                setIsOpen({ ...isOpen, success: false })
            }} />
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => {
                setIsOpen({ ...isOpen, fails: false })
            }} />


        </form>
    )
}
