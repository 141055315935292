import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img from "../../website/assets/images/pexels-cottonbro-3201583.jpg";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../api'
import Feedback from '../../admin/components/feedback'
import logo from "../../website/assets/logo/logo.png"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const [showModal, setshowModal] = useState(false)
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState([])
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(api+'/api/connexion', { email, password })
      .then((res)=>{
        // setUserInfo(res.data)
        setshowModal(true)

        localStorage.setItem("soro-id", res.data.user.id)
        localStorage.setItem("soro-email", res.data.user.email)
        localStorage.setItem("soro-photo", res.data.user.photo)
        localStorage.setItem("soro-role", res.data.role)
        
        setTimeout(() => {
          
        // console.log(res.data)
        switch (res.data.role) {
          case "admin":
            // navigate("/admin")
            window.location.href = "/admin/"
            break;
          case "prestataire":
            // navigate("/pro")
            window.location.href = "/pro/";
            
            break;
          default:
            // navigate("/")
            window.location.href = "/";
            break;
        }   
        }, 2000)
      })
      .catch((err)=>{
        console.log(err)
        setShowErrorModal(true)
      })

    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setError('Une erreur s\'est produite. Veuillez réessayer.');
    }
  }

  return (
    <div className="flex h-screen">
      {/* Section de formulaire de connexion */}
      <div className="w-full lg:w-3/5 flex  justify-center mt-20">
        <div className="max-w-md w-full p-6">
        <div className="flex justify-center mb-10"> <img src={logo} alt="logo"  className='w-28 h-16'/></div>
          <h1 className="text-3xl font-semibold mb-6 text-black text-center">Connexion</h1>
          {/* <h1 className="text-sm font-semibold mb-6 text-gray-500 text-center">Rejoignez notre communauté avec un accès tout le temps et gratuitement</h1> */}

          <form onSubmit={handleLogin} className="space-y-4 mt-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-start mb-1">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:border-primary  focus:outline focus:outline-2 focus:outline-primary  focus:ring-gray-300 transition-colors duration-300"
              />
            </div>
            <div>
              <div className="flex justify-between">
               <label htmlFor="password" className="block text-sm font-medium text-gray-700 text-start mb-1">Mot de passe</label>
               <Link to={"/forgotPassword"} className='hover:underline italic  text-sm text-gray-600'>Mot de passe oublié ?</Link>
              </div>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:border-primary  focus:outline focus:outline-2 focus:outline-primary  focus:ring-gray-300 transition-colors duration-300"
              />
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <div>
              <button
                type="submit"
                className="w-full bg-black-900 text-white p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300"
              >
                Se connecter
              </button>
            </div>
          </form>

          <div className="mt-4 text-sm text-gray-600 text-center">
            <p>Pas de compte? <Link to="/register" className="text-primary hover:underline">Inscrivez-vous ici</Link></p>
          </div>
        </div>
      </div>

      <div className="hidden lg:flex flex-1 bg-white text-black">
        <img src={img} alt="" className='w-full h-screen object-cover'/>
      </div>
      <Feedback isOpen={showModal} onClose={()=>setshowModal(false)} message='Connexion réussie !'/>
      <Feedback isOpen={showErrorModal} onClose={()=>setShowErrorModal(false)} type='error' message='Email ou mot de passe invalide !'/>
    </div>
  );
};

export default Login;
