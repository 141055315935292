import React, { useState } from 'react';
import { BiCamera } from 'react-icons/bi';

const ProfileImagePicker = ({ initialImage, onImageChange }) => {
    const [selectedImage, setSelectedImage] = useState(initialImage || null);
  
    const handleImageChange = (e) => {
      const { files } = e.target;
      if (files && files.length > 0) {
        const file = files[0];
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
        onImageChange(e); // Pass the entire event for consistent handling
      }
    };
  
    const handleClick = () => {
      document.getElementById('photoUpdate').click();
    };
  
    return (
      <div className="relative flex justify-center items-center">
        <img
          src={selectedImage || 'default-placeholder.png'} // Use a default placeholder if no image is provided
          alt="Profile"
          className="w-32 h-32 rounded-full object-cover"
        />
        <label
          htmlFor="photoUpdate"
          className="absolute ml-24 mt-20 cursor-pointer"
        //   onClick={handleClick}
        >
          <BiCamera className="h-10 w-10 p-1 text-gray-900 bg-white border rounded-full" />
        </label>
        <input
          type="file"
          id="photoUpdate"
          name="photo"
          className="hidden"
          onChange={handleImageChange}
        />
      </div>
    );
  };

export default ProfileImagePicker;
