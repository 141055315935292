import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import Logo from "../../../website/assets/logo/logo.png"

const Index = () => {
  const location = useLocation();

  const menuItems = [
    { name: 'Tableau de bord', icon: 'home', path: '/pro/' },
    { name: 'Rendez-vous', icon: 'calendar', path: '/pro/appointments' },
    // { name: 'Mes disponibilités', icon: 'availability', path: '/pro/availability' },
    { name: 'Clients', icon: 'users', path: '/pro/customers' },
    { name: 'Mes services', icon: 'services', path: '/pro/services' },
    // { name: 'Mes offres', icon: 'offers', path: '/pro/offers' },
    { name: 'Abonnement', icon: 'abonnement', path: '/pro/abonnement' },
    // { name: 'Profil', icon: 'offers', path: '/pro/profile' },
    { name: 'Paramètre', icon: 'parameter', path: '/pro/parametre' },
  ];

  return (
    <div className="min-h-screen flex-col w-64 flex-shrink-0 antialiased bg-gray-50 text-gray-800 hidden md:flex">
      <div className="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
        <div className="flex items-center justify-center h-16 border-b">
          <div><img src={Logo} alt="" className="h-10 w-20 my-2" /></div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1 font-medium">
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                {/* <div className="text-sm font-light tracking-wide text-gray-500">Menu</div> */}
              </div>
            </li>
            {menuItems.map((item, index) => (
              <li key={index}>
                {console.log(item.path , location.pathname)}
                <Link
  to={item.path}
  className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-primary hover:bg-opacity-5 text-gray-600 hover:text-primary hover:font-medium border-r-4 ${

    location.pathname === item.path ? ' border-r-[4px] border-r-[#ff953e] bg-primary bg-opacity-5 text-primary ' : ' border-transparent '

  } pr-6 border-r-4 border-r-transparent `}
>
                  <span className="inline-flex justify-center items-center ml-4">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {item.icon === 'home' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
  />
)}
{item.icon === 'calendar' && (
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
)}
{item.icon === 'availability' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M5 8h14M12 4v4m0 8v4m-8-4h16"
  />
)}
{item.icon === 'users' && (
 <path 
 stroke-linecap="round" 
 stroke-linejoin="round" 
 stroke-width="2" 
 d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
)}
{item.icon === 'services' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M9.75 17L8.5 19m-3.5 2l4.5-2.5m0 0l4.5 2.5M9.75 17v2.5m0 0l4.5-2.5M12 13V4m0 0L7 8m5-4l5 4m0 8v5m0 0l3.5-3.5M19 19v-2.5m0 0l-4.5 2.5M19 17l-3.5 3.5"
  />
)}
{item.icon === 'offers' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M9 5v2a7 7 0 006 0V5M5 10h14m-5 5v6m0 0h-4m4 0l-4-6"
  />
)}
{item.icon === 'abonnement' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M7 20h10a1 1 0 001-1V9a1 1 0 00-1-1H7a1 1 0 00-1 1v10a1 1 0 001 1zM4 8V7a2 2 0 012-2h12a2 2 0 012 2v1M7 4h10a1 1 0 011 1v1M4 8h16"
  />
)}
{item.icon === 'parameter' && (
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
    // <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
)}
{item.icon === 'parameter' && (
    // <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
)}

                    </svg>
                  </span>
                  <span className="ml-2 text-sm tracking-wide truncate">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;


// import React from 'react'

// const index = () => {
//   return (
//     <>
//         <div class="min-h-screen flex flex-col w-64 flex-shrink-0 antialiased bg-gray-50 text-gray-800">
//   <div class="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
//     <div class="flex items-center justify-center h-14 border-b">
//       <div>Soro</div>
//     </div>
//     <div class="overflow-y-auto overflow-x-hidden flex-grow">
//       <ul class="flex flex-col py-4 space-y-1 font-medium">
//         <li class="px-5">
//           <div class="flex flex-row items-center h-8">
//             <div class="text-sm font-light tracking-wide text-gray-500">Menu</div>
//           </div>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Tableau de bord</span>
//           </a>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Rendez-vous</span>
//             <span class="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-indigo-500 bg-indigo-50 rounded-full">New</span>
//           </a>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Mes disponibilités</span>
//           </a>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#FFA620]  hover:bg-opacity-10 text-gray-600 hover:text-[#FF7200] hover:font-medium border-r-4 border-transparent hover:border-[#FF7200] pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Clients</span>
//             {/* <span class="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">1.2k</span> */}
//           </a>
//         </li>
//         <li class="px-5">
//           <div class="flex flex-row items-center h-8">
//             <div class="text-sm font-light tracking-wide text-gray-500">Produit & Service</div>
//           </div>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#FFA620]  hover:bg-opacity-10 text-gray-600 hover:text-[#FF7200] hover:font-medium border-r-4 border-transparent hover:border-[#FF7200] pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Mes services</span>
//           </a>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Mes offres</span>
//             <span class="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-green-500 bg-green-50 rounded-full">15</span>
//           </a>
//         </li>
//         <li class="px-5">
//           <div class="flex flex-row items-center h-8">
//             <div class="text-sm font-light tracking-wide text-gray-500">Paramètre</div>
//           </div>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Profil</span>
//           </a>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
//                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
//               </svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Paramètre</span>
//           </a>
//         </li>
//         <li>
//           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
//             <span class="inline-flex justify-center items-center ml-4">
//               <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
//             </span>
//             <span class="ml-2 text-sm tracking-wide truncate">Se déconnecter</span>
//           </a>
//         </li>
//       </ul>
//     </div>
//   </div>
// </div>
//     </>
//   )
// }

// export default index