import Button from '@mui/material/Button';
import photo from '../../../../assets/image/404.png'
import Card from '../../../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoAddOutline, IoCreate, IoEllipsisHorizontal, IoEye, IoPersonCircleOutline, IoSearch, IoShare, IoShareOutline, IoTrash } from 'react-icons/io5'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Input from '../../../../components/form/input'
import GoBack from '../../../../components/btn/goBack';
import Path from '../../../../components/path';

import { api } from '../../../../../api';
import axios from 'axios';

import { exportToExcel } from '../../../../components/ExcelExport';

export default function Offre() {
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const { id } = useParams()
    const handleClose = () => {
        setAnchorEl(null);
    };

    function fetchData() {
        try {
            axios.get(api + '/api/allOffresService/' + id)
                .then(response => setData(response.data))
                .catch(error => console.error(error))
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='w-full flex flex-col gap-y-3 h-screen'>
            <div className="w-full flex flex-row justify-between items-center">
                <div className='flex flex-row gap-x-3 justify-start items-center'>
                    <Button className="flex flex-row gap-x-3" variant="contained" onClick={() => { navigate('/admin/nouveauPrestataire') }}>
                        <IoAddCircle />
                        <span>Nouveau</span>
                    </Button>
                    <Button className="flex flex-row gap-x-3" variant="outlined"
                        onClick={() => {
                            exportToExcel(data, "Offre")
                        }}
                    >
                        <IoShare />
                        <span>Exporter</span>
                    </Button>
                </div>
                <div className='flex flex-row justify-end items-center'>
                    <div className="flex flex-row justify-end items-center gap-x-1">
                        <Input value={search} onChange={(e) => { setSearch(e.target.value) }} />
                        <Button className="flex flex-row gap-x-3" variant="contained">
                            <IoSearch />
                            <span>Rechercher</span>
                        </Button>
                    </div>
                </div>
            </div>
            <Card>
                <div className='w-full flex flex-row justify-between items-center'>
                    <div className='flex flex-row justify-start items-center gap-x-3 mb-2'>
                        <GoBack />
                        <h4 className="">Offre</h4>
                    </div>
                    <Path currentName='Offre' path={[
                        {
                            name: 'Client pro',
                            path: '/admin/prestataire'
                        }
                    ]} />
                </div>
                <table className='w-full max-w-full '>
                    <thead>
                        <tr>
                            <th>NUMERO</th>
                            <th>PHOTO</th>
                            <th>NOM</th>
                            <th>DESCRIPTION</th>
                            <th>PRIX (Ar)</th>
                            <th>DUREE</th>
                            <th>ETAT</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.filter((item) => (search ? item.nom.toLowerCase().includes(search.toLocaleLowerCase()) : item)).map((item, index) => (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>
                                        {
                                            item.photo != null ? (
                                                <img src={api + '/storage/' + item.photo} alt={item.nom} className='w-8 h-8 rounded-full' />
                                            ) : (
                                                <IoPersonCircleOutline className='w-8 h-8' />
                                            )
                                        }
                                    </td>
                                    <td>{item.nom}</td>
                                    <td>{item.description}</td>
                                    <td>{item.prix}</td>
                                    <td>{item.duree_heure}h{item.duree_minute}</td>
                                    <td>{item.status}</td>
                                    <td className='flex flex-row justify-end items-center text-xl gap-x-3'>
                                        {/* <IoEye className='text-lg' onClick={() => { exportToExcel(data,"files") }} /> */}
                                        {/* <IoCreate className='text-lg' onClick={() => { navigate(`/admin/modificationService/${item.id}`) }} />
                                        <IoTrash className='text-lg' /> */}
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </Card>


        </div>
    )
}
