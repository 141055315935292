import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import ServiceCard from '../../components/Cards/ServiceCard'
import { ServiceData } from '../../../website/Datas/Datas'
import { api } from '../../../api'
import axios from 'axios'
import Modal from '../../components/Modal/Modal'
import Loading from '../../components/Loading'
// import ServiceCard from "../Home/partials/Services/ServiceCard";

const Index = () => {
  const [formData, setFormData] = useState({
    serviceType: '',
    provider: '',
    location: '',
    sector: ''
  })
  const [loading, setLoading] = useState(true)
  const { setTitle } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1)
  const [showViewModal, setShowViewModal] = useState(false)
  const itemsPerPage = 6
  const navigate = useNavigate()
  // const {id} = useParams()
  const id = 5
  const [services, setServices] = useState([])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculer les éléments à afficher pour la page actuelle
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ServiceData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculer le nombre total de pages
  const totalPages = Math.ceil(ServiceData.length / itemsPerPage);



  useEffect(() => {
    setTitle('Service');

    axios.get(api + `/api/servicesUserPro/${id}`)
      .then(response => {
        setServices(response.data);
        setLoading(false); // Arrêter l'indicateur de chargement
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des services:", error);
      })
      
  }, [setTitle])

  return (
    <div className='border-line min-h-screen mx-6'>
      <div className="flex justify-between items-center">
        <p className='text-xl font-semibold'>Liste des services</p>
        <Button onClick={() => navigate('/pro/service/add')}>Nouveau Service</Button>
      </div>
      {loading ? (
          <div className="mt-40 flex justify-center"><Loading width={16}/></div>
        ) : (
      <div className="grid grid-cols-1  lg:grid-cols-3 xl:grid-cols-4 gap-4 my-6">
        {services.map((item, index) => (
          <ServiceCard key={index} {...item} serviceData={item} />
        ))}
      </div>
        )}

     
    </div>
  )
}

export default Index
