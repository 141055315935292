import React, { useEffect, useState } from 'react'
import Card from '../../components/Card'
import { IoPeople } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../api'
import Pie from '../../components/chart/pie'
export default function Dashboard() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [count, setCount] = useState({
        
    })

    const [totalUser, setTotalUser] = useState([])
    const [totalClient, setTotalClient] = useState([])
    const [totalPrest, setTotalPrest] = useState([])
    const [totalService, setTotalService] = useState([])

    function fetchData() {
        try {
            axios.get(api + '/api/getAboRecent')
                .then(response => {
                    setData(response.data)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        } catch (error) {
            console.error(error);
        }
    }

    function getNombreUser() {
        try {
            axios.get(api + '/api/getNombreUser')
                .then(response => {
                    setTotalUser(response.data)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        } catch (error) {
            console.error(error);
        }
    }

    function getNombreClient() {
        try {
            axios.get(api + '/api/getNombreClient')
                .then(response => {
                    setTotalClient(response.data)
                    // setCount({...count, client: response.data})
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        } catch (error) {
            console.error(error);
        }
    }

    function getNombrePrest() {
        try {
            axios.get(api + '/api/getNombrePrest')
                .then(response => {
                    setTotalPrest(response.data)
                    // setCount({...count, clientPro: response.data})
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        } catch (error) {
            console.error(error);
        }
    }

    function getNombreService() {
        try {
            axios.get(api + '/api/getNombreService')
                .then(response => {
                    setTotalService(response.data)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData()
        getNombreUser()
        getNombreClient()
        getNombrePrest()
        getNombreService()
    }, [])
    return (
        <div className='w-full h-screen'>
            <div className='grid max-lg:grid-cols-3 max-md:grid-cols-2 lg:grid-cols-4 gap-4'>
                <Card>
                    <div className='flex flex-row justify-between'>
                        <span className='text-3xl font-semibold'>{totalUser}</span>
                        <span className='bg-primary/10 w-[42px] h-[42px] rounded-full flex flex-row justify-center items-center'>
                            <IoPeople className='text-primary' />
                        </span>
                    </div>
                    <span className=' w-full text-slate-400 text-sm text-left'>Utilisateur</span>
                </Card>
                <Card>
                    <div className='flex flex-row justify-between'>
                        <span className='text-3xl font-semibold'>{totalClient}</span>
                        <span className='bg-primary/10 w-[42px] h-[42px] rounded-full flex flex-row justify-center items-center'>
                            <IoPeople className='text-primary' />
                        </span>
                    </div>
                    <span className=' w-full text-slate-400 text-sm text-left'>Client</span>
                </Card>
                <Card>
                    <div className='flex flex-row justify-between'>
                        <span className='text-3xl font-semibold'>{totalPrest    }</span>
                        <span className='bg-primary/10 w-[42px] h-[42px] rounded-full flex flex-row justify-center items-center'>
                            <IoPeople className='text-primary' />
                        </span>
                    </div>
                    <span className=' w-full text-slate-400 text-sm text-left'>Client professionnel</span>
                </Card>
                <Card>
                    <div className='flex flex-row justify-between'>
                        <span className='text-3xl font-semibold'>{totalService}</span>
                        <span className='bg-primary/10 w-[42px] h-[42px] rounded-full flex flex-row justify-center items-center'>
                            <IoPeople className='text-primary' />
                        </span>
                    </div>
                    <span className=' w-full text-slate-400 text-sm text-left'>Services</span>
                </Card>

            </div>
            <div className='w-full flex max-lg:flex-col lg:flex-row mt-3 lg:justify-between items-start gap-4'>
                <div className="max-lg:w-full lg:w-7/12">
                    <Card>
                        <div className="flex flex-row justify-between mb-3 items-center">
                            <h4>Abonnement récent</h4>
                            <button className="text-blue-500" type="button" onClick={() => {
                                navigate('/admin/abonnement/0')
                            }}>
                                Voir tous
                            </button>
                        </div>
                        <table className='w-full'>
                            <thead>
                                <tr>
                                    <th>Numéro</th>
                                    <th>Nom</th>
                                    <th>Type</th>
                                    <th>Prix (Ar)</th>
                                    <th>Etat</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.user?.nom}</td>
                                            <td>{item.type}</td>
                                            <td>{item.prix}</td>
                                            <td>{item.etat}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </Card>
                </div>
                <div className="max-lg:w-full lg:w-5/12">
                    <Card>
                        <Pie data={[
                            { id: 0, value: totalClient, label: 'Client' },
                            { id: 1, value: totalPrest, label: 'Client pro' },
                            // { id: 2, value: count.admin, label: 'Admin' },
                        ]} />
                    </Card>
                </div>
            </div>
        </div>
    )
}
