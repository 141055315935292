import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { TiWatch } from 'react-icons/ti'
import { FaEllipsisH } from 'react-icons/fa'
import Button from '../../components/Button'
import dayjs from 'dayjs'
import 'dayjs/locale/fr' // Importer la localisation française
import customParseFormat from 'dayjs/plugin/customParseFormat'
import AddAppointementModal from '../../components/Modal/AddAppointementModal'
import Loading from "../../components/Loading"
import EditAppointementModal from '../../components/Modal/EditAppointementModal'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import ButtonDropdown from '../../components/Dropdown/ButtonDropdown'
import axios from 'axios'
import { api } from '../../../api'
import Modal from "../../components/Modal/Modal"
import NoResults from '../../components/NoResult'
import AppointmentStatus from '../../components/AppointmentStatus'
import TruncateText from "../../../website/components/TruncateText"


dayjs.locale('fr'); // Configurer dayjs pour utiliser le français
dayjs.extend(customParseFormat); // Étendre dayjs pour supporter des formats personnalisés

const Index = () => {
  const [loading, setLoading] = useState(true)
  const { setTitle } = useOutletContext();
  const [appointments, setAppointments] = useState([])
  const [dateFilter, setDateFilter] = useState('Tous')
  const [statusFilter, setStatusFilter] = useState('Tous')
  const [showModal, setShowModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  // const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [appointementSelected, setappointementSelected] = useState({})

  const [idSelected, setIdSelected] = useState(0)
  const [emailSelected, setEmailSelected] = useState([])
  const [dateSelected, setDateSelected] = useState([])
  const [heureSelected, setHeureSelected] = useState([])
  const [minuteSelected, setMinuteSelected] = useState([])
  const [nomOffreSelected, setNomOffreSelected] = useState([])

  const idPrest = 5 //Soloina localStorage

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  }
  const cancel = () => {
    // console.log(idSelected)
    // envoyer un email d'annulation
    const formData = new FormData();
    formData.append('email',emailSelected)
    formData.append('date',dateSelected)
    formData.append('heure',heureSelected)
    formData.append('minute',minuteSelected)
    formData.append('nomOffre',nomOffreSelected)

    // console.log(formData.email)

    axios.post(api+ `/api/annulerRdv/${idSelected}`)
    .then((res)=>{
      // console.log(res)
      setShowModal(false)

        axios.post(api+ `/api/emailAnnulerRdv`, formData)
        .then((res)=>{
          console.log(res)
          setShowModal(false)
        })
        .catch((err)=>console.log(err))

    })
    .catch((err)=>console.log(err))

  }
  const accept = () => {
    // envoyer un email de confirmation
    // axios.post(api+"/api/sendEmailCancel/"+idSelected)
    // .then((res)=>{
    //   console.log(res)
    //   setShowModal(false)
    // })
    // .catch((err)=>console.log(err))

    const formData = new FormData();
    formData.append('email',emailSelected)
    formData.append('date',dateSelected)
    formData.append('heure',heureSelected)
    formData.append('minute',minuteSelected)
    formData.append('nomOffre',nomOffreSelected)

    // console.log(formData.email)

    axios.post(api+ `/api/confirmerRdv/${idSelected}`)
    .then((res)=>{
      // console.log(res)
      setShowModal(false)

        axios.post(api+ `/api/emailConfirmerRdv`, formData)
        .then((res)=>{
          console.log(res)
          setShowModal(false)
        })
        .catch((err)=>console.log(err))

    })
    .catch((err)=>console.log(err))

  }



  useEffect(() => {

    setTitle('Rendez-vous');
    // setAppointments(fakeAppointments);

    axios.get(`${api}/api/getTousRdvPrest/${idPrest}`)
      .then(response => {
        setAppointments(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.error("There was an error fetching the customer data!", error);
      })


  }, [setTitle])

  const handleDeleteCloseModal = () => {
    setShowDeleteModal(false);
  };

  const fakeAppointments = [
    { id: 1, date: '25-08-2024', time: '09:00 - 09:30', reason: 'Interview', name: 'John DOE', status: 'Confirmé' },
    { id: 2, date: '26-08-2024', time: '10:00 - 10:30', reason: 'Consultation', name: 'Jane SMITH', status: 'En attente' },
    { id: 3, date: '24-08-2024', time: '11:00 - 11:30', reason: 'Discussion', name: 'James BOND', status: 'Annulé' },
    { id: 4, date: '27-08-2024', time: '12:00 - 12:30', reason: 'Mise à jour', name: 'Mary JOHNSON', status: 'Confirmé' },
    { id: 5, date: '23-08-2024', time: '13:00 - 13:30', reason: 'Suivi', name: 'Albert EINSTEIN', status: 'En attente' },
    { id: 6, date: '25-08-2024', time: '14:00 - 14:30', reason: 'Réunion', name: 'Isaac NEWTON', status: 'Confirmé' },
  ];

  const filterByDate = (appointmentDate) => {
    const today = dayjs().format('DD-MM-YYYY');
    const tomorrow = dayjs().add(1, 'day').format('DD-MM-YYYY');
    const appointmentDay = dayjs(appointmentDate, 'DD-MM-YYYY').format('DD-MM-YYYY');

    if (dateFilter === 'Aujourd\'hui' && appointmentDay === today) {
      return true;
    } else if (dateFilter === 'Demain' && appointmentDay === tomorrow) {
      return true;
    // } else if (dateFilter === 'Passé' && dayjs(appointmentDate, 'DD-MM-YYYY').isBefore(today, 'day')) {
    //   return true;
    } else if (dateFilter === 'Tous') {
      return true;
    }
    return false;
  };

  const filteredAppointments = appointments
    .filter(appointment => statusFilter === 'Tous' || appointment.etat === statusFilter)
    .filter(appointment => filterByDate(appointment.date))

  return (
    <div className='p-6 mx-4 border-line min-h-[600px]'>
          
      <div className="grid grid-1 md:grid-cols-9">
        <div className="col-span-2 min-h-[600px] hidden md:block">
          <ul className='text-sm mr-4 font-medium'>
            {['Tous', 'Confirmé', 'En attente', 'Annulé'].map((filter) => (
              <li
                key={filter}
                onClick={() => setStatusFilter(filter)}
                className={`cursor-pointer p-2 ${statusFilter === filter ? 'bg-orange-400/10 text-primary  border-r-[4px] border-r-[#fb923c]' : ' text-gray-600'}`}
              >
                {filter}
              </li>
            ))}
          </ul>
          
        </div>
        <div className="col-span-7 border-l border-l-1 px-6">
          <div className="flex justify-between items-center mb-4">
            <div className="grid grid-cols-3 w-72 gap-1 rounded-md bg-gray-100 p-1 text-sm">
              {['Tous', 'Aujourd\'hui', 'Demain'].map((filter, index) => (
                <div key={index}>
                  <input type="radio" name="dateFilter" id={filter} value={filter} className="peer hidden" />
                  <label 
                    htmlFor={filter} 
                    className={`input-radio cursor-pointer p-2 rounded-md ${dateFilter === filter ? 'bg-orange-500 text-white' : ' bg-transparent'}`}
                    onClick={() => setDateFilter(filter)}
                  >
                    {filter}
                  </label>
                </div>
              ))}
            </div>
            {/* <Button onClick={handleOpenModal}>Nouveau rdv</Button> */}
          </div>
          <div>
          {loading ? (
          <div className="mt-32 flex justify-center"><Loading width={20}/></div>
        ) : (
            filteredAppointments.length === 0 ?
            <NoResults showMessage={true} subtitle={"Vous avez aucun rendez-vous de prévu !"} message={"Les rendez-vous seront affiché ici."}/>
              :
            filteredAppointments.map((appointment, index) => {
              const isEven = index % 2 === 0;
              return (
                <div 
                  key={appointment.id} 
                  className={`rdv-card flex justify-between items-center mb-4 ${isEven ? 'bg-white' : 'bg-gray-100'}`}
                >
                  <div className='flex gap-4'>
                    <div 
                      className={`dateSection ${isEven ? 'bg-gray-200' : 'bg-white'} text-center rounded-md p-2 max-h-16 `}
                    >
                      <span>{dayjs(appointment.date, 'YYYY-MM-DD').format('DD')}</span>
                      <span>{dayjs(appointment.date, 'YYYY-MM-DD').format('MMMM').charAt(0).toUpperCase()}
                      {dayjs(appointment.date, 'YYYY-MM-DD').format('MMMM').charAt(1).toLowerCase()}
                      {dayjs(appointment.date, 'YYYY-MM-DD').format('MMMM').charAt(2).toLowerCase()}
                      </span>
                    </div>

                    <div className="grid grid-cols-3 w-[500px] text-sm items-center border-l pl-3 gap-x-4 gap-y-1">
                      <div className='flex items-center gap-1 '><TiWatch/> {appointment.heure}:{appointment.minute}</div>
                      {/* <div>Raison: {appointment.raison}</div> */}
                      <div className='flex items-center gap-1'><TiWatch/>  <TruncateText maxChars={15}>{appointment.user?.email}</TruncateText></div>
                      <div className='flex'><AppointmentStatus text={appointment.etat} /></div>
                      <div>Offre: {appointment.offre?.nom}</div>
                      <Link to={`/pro/customer/${appointment.user?.id}`} className='text-primary underline'>Voir le profil</Link>

                    </div>
                  </div>

                  {/* <div className={" text-sm px-6 py-2 rounded-full font-medium  bg-neutral-50 border "+
                    ((appointment.status === "Confirmé") && "text-green-500")+
                    ((appointment.status === "Annulé") && "text-red-500")+
                    ((appointment.status === "En attente") && "text-yellow-500")

                  }>{appointment.status}</div> */}
                  <div className=" cursor-pointer mr-2 rounded-md flex items-center">

                    {/* <FaEllipsisH className='text-gray-500'/>   */}
                    {/* <ButtonDropdown appointementStatus={appointment.status} key={index}></ButtonDropdown> */}
                    {/* icon view */}
                    <button 
                    onClick={()=>{
                      setIdSelected(appointment.id)
                      
                      setappointementSelected(appointment)
                      setShowViewModal(true)}}
                    className="flex items-center justify-center hover:bg-neutral-50  p-2 rounded-lg">
                     <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 18.75C6.17 18.75 3.43 12.56 3.31 12.3C3.27039 12.2049 3.25 12.103 3.25 12C3.25 11.897 3.27039 11.7951 3.31 11.7C3.43 11.44 6.17 5.25 12 5.25C17.83 5.25 20.57 11.44 20.69 11.7C20.7296 11.7951 20.75 11.897 20.75 12C20.75 12.103 20.7296 12.2049 20.69 12.3C20.57 12.56 17.83 18.75 12 18.75ZM4.83 12C5.42 13.15 7.83 17.25 12 17.25C16.17 17.25 18.58 13.15 19.17 12C18.58 10.85 16.17 6.75 12 6.75C7.83 6.75 5.42 10.85 4.83 12Z" fill="currentColor"/>
                      <path d="M12 15.25C11.3572 15.25 10.7289 15.0594 10.1944 14.7023C9.65994 14.3452 9.24338 13.8376 8.99739 13.2437C8.75141 12.6499 8.68705 11.9964 8.81245 11.366C8.93785 10.7355 9.24738 10.1564 9.7019 9.7019C10.1564 9.24738 10.7355 8.93785 11.366 8.81245C11.9964 8.68705 12.6499 8.75141 13.2437 8.99739C13.8376 9.24338 14.3452 9.65994 14.7023 10.1944C15.0594 10.7289 15.25 11.3572 15.25 12C15.2474 12.8611 14.9041 13.6863 14.2952 14.2952C13.6863 14.9041 12.8611 15.2474 12 15.25ZM12 10.25C11.6539 10.25 11.3155 10.3526 11.0278 10.5449C10.74 10.7372 10.5157 11.0105 10.3832 11.3303C10.2508 11.6501 10.2161 12.0019 10.2836 12.3414C10.3512 12.6809 10.5178 12.9927 10.7626 13.2374C11.0073 13.4822 11.3191 13.6489 11.6586 13.7164C11.9981 13.7839 12.3499 13.7492 12.6697 13.6168C12.9895 13.4843 13.2628 13.26 13.4551 12.9722C13.6474 12.6845 13.75 12.3461 13.75 12C13.7474 11.5367 13.5622 11.0931 13.2345 10.7655C12.9069 10.4378 12.4633 10.2526 12 10.25Z" fill="currentColor"/>
                      </svg> 
                    </button>
                    {/* icon edit */}
                    {/* <button 
                    onClick={()=>{
                      setIdSelected(appointment.id)
                      setShowEditModal(true)}}
                    className="flex items-center justify-center hover:bg-blue-50  p-2 rounded-lg hover:text-blue-600">
                     <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4.20999 20.5199C4.11375 20.521 4.01826 20.5029 3.92902 20.4669C3.83977 20.4308 3.75854 20.3775 3.68999 20.3099C3.61139 20.2323 3.55092 20.1383 3.51288 20.0346C3.47485 19.9308 3.4602 19.82 3.46999 19.7099L3.77999 15.8699C3.79328 15.6916 3.87156 15.5244 3.99999 15.3999L15.06 4.33995C15.6762 3.76286 16.4961 3.45361 17.34 3.47995C18.1784 3.48645 18.9828 3.81181 19.59 4.38995C20.1723 4.98795 20.5073 5.7839 20.5277 6.61837C20.5481 7.45284 20.2524 8.26421 19.7 8.88995L8.62999 19.9999C8.50609 20.1234 8.34386 20.201 8.16999 20.2199L4.27999 20.5699L4.20999 20.5199ZM5.20999 16.2599L4.99999 18.9999L7.73999 18.7499L18.64 7.82995C18.8525 7.57842 18.9884 7.27118 19.0314 6.94472C19.0745 6.61827 19.0229 6.28631 18.8828 5.9883C18.7428 5.69028 18.5201 5.43873 18.2413 5.26354C17.9625 5.08834 17.6393 4.99685 17.31 4.99995C17.0936 4.98621 16.8766 5.01633 16.6721 5.0885C16.4676 5.16067 16.2798 5.27341 16.12 5.41995L5.20999 16.2599Z" fill="currentColor"/>
                    </svg> 
                    </button> */}
                    {/* icon confirmer */}
                    <button 
                    onClick={()=>{
                      setIdSelected(appointment.id)
                      setEmailSelected(appointment.user?.email)
                      setDateSelected(appointment.date)
                      setHeureSelected(appointment.heure)
                      setMinuteSelected(appointment.minute)
                      setNomOffreSelected(appointment.offre?.nom)
                      setShowAcceptModal(true)}}
                    className="flex items-center justify-center hover:bg-green-50  p-2 rounded-lg hover:text-green-600">
                     <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.5 15.25C10.307 15.2353 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.8601 6.91133 11.7029 6.93756 11.55C6.96379 11.3971 7.03676 11.2562 7.14643 11.1465C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9114 7.86003 10.9332 7.99998 11L10.47 13.47L19 5.00004C19.1399 4.9332 19.2972 4.91139 19.45 4.93762C19.6029 4.96385 19.7439 5.03682 19.8535 5.14649C19.9632 5.25616 20.0362 5.39713 20.0624 5.54999C20.0886 5.70286 20.0668 5.86009 20 6.00004L11 15C10.8724 15.1455 10.6929 15.2353 10.5 15.25Z" fill="currentColor"/>
                      <path d="M12 21C10.3915 20.9974 8.813 20.5638 7.42891 19.7443C6.04481 18.9247 4.90566 17.7492 4.12999 16.34C3.54037 15.29 3.17596 14.1287 3.05999 12.93C2.87697 11.1721 3.2156 9.39921 4.03363 7.83249C4.85167 6.26578 6.1129 4.9746 7.65999 4.12003C8.71001 3.53041 9.87134 3.166 11.07 3.05003C12.2641 2.92157 13.4719 3.03725 14.62 3.39003C14.7224 3.4105 14.8195 3.45215 14.9049 3.51232C14.9903 3.57248 15.0622 3.64983 15.116 3.73941C15.1698 3.82898 15.2043 3.92881 15.2173 4.03249C15.2302 4.13616 15.2214 4.2414 15.1913 4.34146C15.1612 4.44152 15.1105 4.53419 15.0425 4.61352C14.9745 4.69286 14.8907 4.75712 14.7965 4.80217C14.7022 4.84723 14.5995 4.87209 14.4951 4.87516C14.3907 4.87824 14.2867 4.85946 14.19 4.82003C13.2186 4.52795 12.1987 4.43275 11.19 4.54003C10.193 4.64212 9.22694 4.94485 8.34999 5.43003C7.50512 5.89613 6.75813 6.52088 6.14999 7.27003C5.52385 8.03319 5.05628 8.91361 4.77467 9.85974C4.49307 10.8059 4.40308 11.7987 4.50999 12.78C4.61208 13.777 4.91482 14.7431 5.39999 15.62C5.86609 16.4649 6.49084 17.2119 7.23999 17.82C8.00315 18.4462 8.88357 18.9137 9.8297 19.1953C10.7758 19.4769 11.7686 19.5669 12.75 19.46C13.747 19.3579 14.713 19.0552 15.59 18.57C16.4349 18.1039 17.1818 17.4792 17.79 16.73C18.4161 15.9669 18.8837 15.0864 19.1653 14.1403C19.4469 13.1942 19.5369 12.2014 19.43 11.22C19.4201 11.1169 19.4307 11.0129 19.461 10.9139C19.4914 10.8149 19.5409 10.7228 19.6069 10.643C19.6728 10.5631 19.7538 10.497 19.8453 10.4485C19.9368 10.3999 20.0369 10.3699 20.14 10.36C20.2431 10.3502 20.3471 10.3607 20.4461 10.3911C20.5451 10.4214 20.6372 10.471 20.717 10.5369C20.7969 10.6028 20.863 10.6839 20.9115 10.7753C20.9601 10.8668 20.9901 10.9669 21 11.07C21.1821 12.829 20.842 14.6026 20.0221 16.1695C19.2022 17.7363 17.9389 19.0269 16.39 19.88C15.3288 20.4938 14.1495 20.8755 12.93 21C12.62 21 12.3 21 12 21Z" fill="currentColor"/>
                      </svg> 
                    </button>
                    {/* icon annuler */}
                    <button 
                    onClick={()=>{
                      setIdSelected(appointment.id)
                      setEmailSelected(appointment.user?.email)
                      setDateSelected(appointment.date)
                      setHeureSelected(appointment.heure)
                      setMinuteSelected(appointment.minute)
                      setNomOffreSelected(appointment.offre?.nom)
                      setShowCancelModal(true)}}
                    className="flex items-center justify-center hover:bg-red-50  p-2 rounded-lg hover:text-red-600">
                     <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.06 12L17.48 7.57996C17.5537 7.5113 17.6128 7.4285 17.6538 7.3365C17.6948 7.2445 17.7168 7.14518 17.7186 7.04448C17.7204 6.94378 17.7018 6.84375 17.6641 6.75036C17.6264 6.65697 17.5703 6.57214 17.499 6.50092C17.4278 6.4297 17.343 6.37356 17.2496 6.33584C17.1562 6.29811 17.0562 6.27959 16.9555 6.28137C16.8548 6.28314 16.7555 6.30519 16.6635 6.34618C16.5715 6.38717 16.4887 6.44627 16.42 6.51996L12 10.94L7.58 6.51996C7.43782 6.38748 7.24978 6.31535 7.05548 6.31878C6.86118 6.32221 6.67579 6.40092 6.53838 6.53834C6.40096 6.67575 6.32225 6.86113 6.31882 7.05544C6.3154 7.24974 6.38752 7.43778 6.52 7.57996L10.94 12L6.52 16.42C6.37955 16.5606 6.30066 16.7512 6.30066 16.95C6.30066 17.1487 6.37955 17.3393 6.52 17.48C6.66062 17.6204 6.85125 17.6993 7.05 17.6993C7.24875 17.6993 7.43937 17.6204 7.58 17.48L12 13.06L16.42 17.48C16.5606 17.6204 16.7512 17.6993 16.95 17.6993C17.1488 17.6993 17.3394 17.6204 17.48 17.48C17.6204 17.3393 17.6993 17.1487 17.6993 16.95C17.6993 16.7512 17.6204 16.5606 17.48 16.42L13.06 12Z" fill="currentColor"/>
                      </svg> 
                    </button>
                  </div>
                </div>
              );
            })
            
          )}
          </div>
        </div>
      </div> 
  
    <AddAppointementModal  show={showModal} onClose={handleCloseModal} />
    <EditAppointementModal show={showEditModal} onClose={()=>setShowEditModal(false)} />
    <Modal onClose={()=>setShowViewModal(false)} show={showViewModal} title={"Détail d'un rendez-vous"} okOption={true}>
      <div class=" border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Client
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {appointementSelected.user?.nom}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Contact
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {appointementSelected.user?.contact}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Offre
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {appointementSelected.offre?.nom}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Durée
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {appointementSelected.offre?.duree_heure}h {appointementSelected.offre?.duree_minute}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Conbtact
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {appointementSelected.user?.contact}
                </dd>
            </div>
          
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Etat
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {appointementSelected.etat}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Date de rendez-vous
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {appointementSelected.date} à {appointementSelected.heure}:{appointementSelected.minute}
                </dd>
            </div>
          
        </dl>
      </div>
    </Modal>
      {/* cancel modal appointement */}
      <ConfirmModal 
        show={showCancelModal} 
        onClose={()=>setShowCancelModal(false)}
        variant='danger'
        title="Annulation"
        text="Êtes-vous sûr de vouloir annuler ce rendez-vous"
        buttonText="Oui, Annuler"
        buttonCancelText="Non, abandoner"
        action={cancel}
      />
      {/* approuve modal appointement */}
      <ConfirmModal 
        show={showAcceptModal} 
        onClose={()=>setShowAcceptModal(false)}
        variant='primary'
        title="Confirmation"
        text="Êtes-vous sûr de vouloir confirmer ce rendez-vous"
        buttonText="Confirmer"
        action={accept}
      />
    </div>

   
  )
}

export default Index;
