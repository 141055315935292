import React, { useEffect, useState } from 'react';
import boat from '../../assets/images/boat.jpg';
import arrowleft from "../../../website/assets/icons/angle-right.svg";
import { useNavigate, useParams } from 'react-router-dom'
import {serviceDetail, offerByIdService, offreData} from '../../Datas/Datas'
import axios from 'axios'
import Calendar from '../../components/Calendar'
import SlotSelection from '../../components/Slot/SlotSelection'
import starfull from "../../assets/icons/fullstar.png"
import staroutline from "../../assets/icons/staroutline.png"
import { IoLocation } from 'react-icons/io5'
import Modal from '../../components/modal/Modal'
import { BsCalendar } from 'react-icons/bs';
import { api } from '../../../api';
import Slots from '../../components/Slot/Slots';
import Calendar1 from '../../components/Calendar/Calendar1';


const AddBooking = ({item}) => {
    const {id}= useParams()
    const navigate = useNavigate()
    // const [service, setService] = useState({});
    // const [offres, setOffres] = useState([]);
    const [service, setService] = useState(serviceDetail);
    const [offre, setOffres] = useState([])
    const [offer, setOffer] = useState([])
    const [timeSelected, setTimeSelected] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    // Fonction pour ajouter un zéro devant les jours et mois inférieurs à 10
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const [hourSelected, setHourSelected] = useState(null);
    const [minuteSelected, setMinuteSelected] = useState(null);

    // Obtention de la date courante
    const today = new Date();
    const day = addLeadingZero(today.getDate());
    const month = addLeadingZero(today.getMonth() + 1); // Les mois commencent à 0 en JavaScript, donc on ajoute 1
    const year = today.getFullYear();

    // Initialisation de selectedDate avec la date courante au format "DD-MM-YYYY
  
    const [dateSelected, setDateSelected] = useState(`${day}-${month}-${year}`)

    const formatDate = (dateString) => {
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
  };

    

    useEffect(() => {
        // Appel API pour récupérer les détails du service
        axios.get(api + `/api/services/${id}`)
            .then(response => response.json())
            .then(data => setService(data))
            .catch(error => console.error('Erreur lors de la récupération du service:', error));
        
        // Appel API pour récupérer les offres associées
        axios.get(api + `/api/getOffreJourHeureOuvrable/${id}`)
            .then(response => {
              // Traiter la réponse si tout se passe bien
              setOffer(response.data)
              console.log(response.data)
            })
            .catch(error => console.error('Erreur lors de la récupération des offres:', error))
    }, [id])

    const handleSubmit = () => {

      const formattedDate = formatDate(dateSelected);
      const userId = parseInt(localStorage.getItem("soro-id"));


      const dataForm = new FormData()
      dataForm.append('date', formattedDate)
      dataForm.append('heure', hourSelected)
      dataForm.append('minute', minuteSelected)
      dataForm.append('offre_id', id)
      // dataForm.append('user_id', userId.toString())
      dataForm.append('user_id', userId);

      // api pour prendre un rdv ; id: idOffre
      axios.post(api + `/api/ajoutRdv`, dataForm)
            .then(response => {
              // Traiter la réponse si tout se passe bien
              console.log('Rendez-vous ajouté avec succès')
            })
            .catch(error => console.error('Erreur lors de la récupération des offres:', error))
    }
    
  return (
    <>
     <div className='pt-16'>
            <section className="px-5 py-10 text-start">
                <button className="p-2 border bg-white my-5 rounded-full ml-16" onClick={() => navigate('/service/1')}>
                    <img src={arrowleft} alt="arrow" className='w-5 h-5 rotate-180' />
                </button>
                <div className="container grid grid-cols-12 mx-auto gap-y-6 md:gap-10">
                    <div className="flex flex-col gap-5 col-span-7 bg-white rounded-md py-6 px-10">
                      <div className="relative flex col-span-12 bg-center bg-no-repeat bg-cover object-cover xl:col-span-7 lg:col-span-5 md:col-span-7 h-96" >
                        <img src={`${api}/storage/${offer.photo}`} alt="photo" className='absolute w-full h-full object-cover rounded-md '/>
                    </div>
                    <div>
                    <h4 className="text-xl mb-4 font-bold">{offer.nom}</h4>
                    <div className="flex justify-between items-center">
                    <div>
                        
                         Durée : {offer.duree_heure } {offer.duree_heure && " h" } {offer?.duree_minute?.toString().padStart(2, '0')} {!offer.duree_heure && " minutes" }
                      </div>
                      <div>{offer.prix}{offer.prix && " Ariary"} </div> 
                    </div>

                    <hr className="my-6"/>
                    <h5 className="font-bold mb-2">Description</h5>
                    <p>{offer.description}</p>
                  </div>
                    </div>
                    

                    {/* Colonne droite: Liste des offres */}
                    <div className=" xl:col-span-5 lg:col-span-5 text-start col-span-12 bg-white px-16 py-10">
                      Selectionner une date
                        <Calendar1 setDateSelected={setDateSelected} openingDays={{
                          "Dim": offer?.service?.jour_ouvrable?.dimanche,
                          "Lun": offer?.service?.jour_ouvrable?.lundi,
                          "Mar": offer?.service?.jour_ouvrable?.mardi,
                          "Mer": offer?.service?.jour_ouvrable?.mercredi,
                          "Jeu": offer?.service?.jour_ouvrable?.jeudi,
                          "Ven": offer?.service?.jour_ouvrable?.vendredi,
                          "Sam": offer?.service?.jour_ouvrable?.samedi
                        }}/>
                      <p className='pt-10 p-5'>Seléctionner une heure</p>
                       

        <Slots
          dateSelected={dateSelected}
          openingHour={offer?.service?.heure_ouvrable?.heure_ouv}
          openingMinute={offer?.service?.heure_ouvrable?.min_ouv}
          breakStartHour={offer?.service?.heure_ouvrable?.heure_debut_pause}
          breakStartMinute={offer?.service?.heure_ouvrable?.min_debut_pause}
          breakEndHour={offer?.service?.heure_ouvrable?.heure_fin_pause}
          breakEndMinute={offer?.service?.heure_ouvrable?.min_fin_pause}
          closingHour={offer?.service?.heure_ouvrable?.heure_ferm}
          closingMinute={offer?.service?.heure_ouvrable?.min_ferm}
          intervalHour={offer.duree_heure}
          intervalMinute={offer.duree_minute}
            setTimeSelected={setTimeSelected}
            setHourSelected={setHourSelected}
            setMinuteSelected={setMinuteSelected}
            textSize="text-lg"
        />
                        {/* {hourSelected +" "+minuteSelected+" "+timeSelected} */}
                        <button className='bg-black-900 text-white w-full rounded-md py-3' onClick={()=>{setShowConfirmModal((dateSelected !== null && timeSelected !==null)?true:false)
                        }}>Réserver maintenant</button>
                    </div>
                </div>
            </section>
          
        </div>
        {/* Modal de confirmation */}
        {showConfirmModal && 
      <Modal width="500px" setShowModal={setShowConfirmModal}>
            <div className="sm:flex sm:items-start">
                <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium" id="modal-headline">
                        Confirmation de RDV
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Vous allez prendre un rendez-vous auprès de {offer.service.nom} pour l'offre {offer.nom}, le {dateSelected} à {timeSelected} ?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                onClick={handleSubmit}
                
                type="button" data-behavior="commit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Enregister
                </button>
                <button
                onClick={()=>setShowConfirmModal(false)}
                type="button" data-behavior="cancel" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm">
                    Annuler
                </button>
            </div>
      </Modal>}
    </>
  )
}

export default AddBooking