// import React from 'react'
// import boat from '../../assets/images/boat.jpg'
import pexelspixabay from '../../assets/images/pexels-pixabay-206359.jpg'

import {ServiceData} from "../../Datas/Datas"
import ServiceCard from "../Home/partials/Services/ServiceCard"
import React, { useEffect, useState } from 'react'
import boat from '../../assets/images/boat.jpg'
import bookmark from '../../assets/icons/bookmark.svg'
import { Link, useParams } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import { FaChevronDown } from 'react-icons/fa6'
import { BiFilter } from 'react-icons/bi'
import profile8 from "../../assets/users/profile8.jpg"
import TruncateText from '../../components/TruncateText'

const ProfilPrestataire = () => {
  const user_id = localStorage.getItem("soro-id");
  const [userInfo, setUserInfo] = useState([])
  const [userRdv, setUserRdv] = useState([])
  const {id} = useParams()
  const [services, setServices] = useState([])

  useEffect(() => {
        
    axios.get(api + `/api/getInfoClient/${id}`)
      .then(response => {
        setUserInfo(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des info des users:", error);
      })

      axios.get(api + `/api/getServiceNombreOffrePro/${id}`)
      .then(response => {
        setServices(response.data);
        console.log(userRdv)
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des rdvs du client:", error);
      })


    }, [])

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  return (

    <>
    <div>

<main className="profile-page ">
<section className=" block h-[350px] ">    
  <div className="absolute top-0 w-full  bg-center bg-cover bg-slate-400 h-[350px]" id='back2'>
    <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
  </div>
  <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" >
    <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
      <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</section>
<div className=" w-full bg-white h-16 shadow">
<div className='grid grid-cols-3 min-w-0 break-words mx-4 md:mx-10  mb-6 rounded-lg gap-4'>
<div className='col-span-1 '></div>
<div className='col-span-2 flex items-center justify-between h-16 mx-4'><h5 className='font-bold text-lg  text-start'>Liste de services</h5>
<div className="flex gap-2 items-center">
   <p className='text-sm font-semibold'>Total: {services.length}</p> 
</div>
</div>
  <div/>
  </div> 
</div>
<div className='grid grid-cols-3 min-w-0 break-words mx-4 md:mx-10  mb-6 rounded-lg gap-4'>
<div className='col-span-1'>
          <div className='bg-white rounded-md p-6 drop-shadow-md mt-[-150px] relative min-h-[400px]'>
              <div className="flex justify-center mt-[-80px] mb-4">
                <img src={api+"/storage/"+userInfo.photo} alt="" className="w-32 h-32 outline outline-4 outline-white rounded-full bg-slate-400" />
              </div>
                 <h2 className='text-2xl font-semibold text-center mb-4'>{userInfo.nom}</h2>
                 <p className='text-center opacity-70 text-sm '>{userInfo.description}</p>
         <hr className='py-6 mt-10'/>
         <ul>
                    <li className='flex items-center gap-2 mb-3'>
                            <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                                <img src={bookmark} alt="..." className='w-6 h-6'/>
                            </div>
                            <p>{userInfo.nom}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.contact}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.email}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.adresse}</p>
                    </li>
                </ul>  
          </div>
          
      </div>

 
      <div className='col-span-2 py-4  min-h-[600px]'>
      {/* <h5 className='font-bold text-lg mb-6 text-start'>Liste de mes rendez-vous</h5>
          <hr className=''/> */}
          <ul>
          {services.map((service, index) => (
                  <li
                      key={index}
                      className='cursor-pointer border-b border-b-slate-500/10 py-3 hover:bg-gray-50 px-4'
                  >
                      <Link to={`/service/${service.id}`} className='flex items-center justify-between'>
                          {/* <div className='bg-green-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'> */}
                          <div className="flex gap-3 items-center">
                            <img src={api+"/storage/"+service.photo} alt="..." className='w-16 h-16' />
                          {/* </div> */}
                          <div className='flex-col flex items-start '>
                              <p className='font-semibold text-lg'>{service.nom}</p>
                             
                                <TruncateText maxChars={70}>{service.description}
                              </TruncateText>
                                  
                          </div>
                          </div>
                              
                          <div className='flex'>{service.offres_count} offre(s)</div>
                      </Link>
                  </li>
              ))}

             
          </ul>
      </div>
</div>
</main>
  </div>
  {/* début booking */}
    
  </>
  )
}

export default ProfilPrestataire