import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import ButtonOutline from '../../components/Button/ButtonOutline';
import img from '../../assets/images/back1.jpg';
import { api } from '../../../api';
import Loading from "../../components/Loading"

const Index = () => {
  const { setTitle } = useOutletContext();
  const navigate = useNavigate();
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  const id = 5; //Soloin localStorage

  // function fetchClients() {
  //   try {
  //       axios.get(api + `/getClientPasserRdvPrest/${id}`)
  //           .then(res => {
  //             setClients(res.data);
  //           })
  //           .catch(err => {
  //               console.error(err);
  //               setLoading(false);
  //           })
  //   } catch (error) {
  //       console.error(error);
  //   } 
  // }

  // Effectuer la requête API pour récupérer les clients
  const fetchClients = async () => {
    try {
      const response = await axios.get(api + `/api/getClientPasserRdvPrest/${id}`); // Remplacez par votre URL d'API
      setClients(response.data); // Stocker les clients récupérés dans l'état
      console.log(response.data)
      setLoading(false); // Arrêter l'indicateur de chargement
    } catch (error) {
      console.error('Erreur lors de la récupération des clients:', error);
      setLoading(false); // Arrêter l'indicateur de chargement même en cas d'erreur
    }
  };

  useEffect(() => {
    setTitle('Client');

    fetchClients();
  }, [setTitle]);

  return (
    <div className='border-line min-h-screen mx-6'>
      <div className="flex justify-between items-center">
        <p className='text-xl font-semibold mb-6'>Liste des clients</p>
      </div>
      {loading ? (
          <div className="mt-40 flex justify-center"><Loading width={16}/></div>
        ) : (
      <div className="overflow border rounded-md">
       
          <table className='w-full table2'>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Email</th>
                <th>Tel</th>
                <th>Adresse</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => (
                <tr key={client.id}>
                  <td>
                    <div className="flex gap-1 items-center">
                      <img src={api + '/storage/' + client.photo || img} alt={client.name} className='w-10 h-10 rounded-md'/>
                      <p>{client.nom}</p>
                    </div>
                  </td>
                  <td>{client.email}</td>
                  <td>{client.contact}</td>
                  <td>{client.adresse}</td>
                  <td>
                    <ButtonOutline onClick={() => navigate(`/pro/customer/${client.id}`)}>Détails</ButtonOutline>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
       
      </div> )}
    </div>
  );
};

export default Index;
