import React from 'react'
import noDataFound from '../../assets/images/emptyFolder.png'

function NoResults({ showMessage, subtitle, message }) {
  if (!showMessage) return null;

  return (
    <div className="flex flex-col items-center justify-center h-full mt-32">
      {/* Image */}
      <img 
        src={noDataFound} 
        alt="Aucun résultat trouvé" 
        className="w-32 h-32 object-contain mb-4 opacity-40" 
      />
      {/* Texte */}
      <p className="text-lg text-gray-700 font-semibold">{subtitle}</p>
      <p className="text-sm text-gray-600">{message}</p>
    </div>
  );
}

export default NoResults;
