import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'

import Login from './auth/login/Login'
import Register from './auth/resgistration/Register'

// CUSTOMER ROUTES
import Home from './website/pages/Home/Home'
import Layout from './website/layout/Layout'
import UserPro from './website/pages/userPro/UserPro'
import UserProProfile from './website/pages/userProProfile/UserProProfile'
import ProfilPrestataire from './website/pages/userProProfile/ProfilPrestataire'
import AddBooking from './website/pages/addBooking/AddBooking'
import Booking from './website/pages/Booking'
import BookingDetail from './website/pages/Booking/BookingDetail'
import Service from './website/pages/Service'
import Profil from './website/pages/Profil'


// PRO CUSTOMER ROUTES

import Dashboard from './websitePro/pages/Dashboard'
import WebsiteProLayout from './websitePro/layout/WebsiteProLayout'
import ServiceDetail from './website/pages/Service/ServiceDetail'
import Appointment from './websitePro/pages/Appointment'
import ServicePro from './websitePro/pages/Service'
import Offer from './websitePro/pages/Offer'
import Availability from './websitePro/pages/Availability'
import Parameter from './websitePro/pages/Parameter'
import Profile from './websitePro/pages/Profile'
import Abonnement from './websitePro/pages/Abonnement'
import Customer from './websitePro/pages/Customer'
import CustomerDetail from './websitePro/pages/Customer/CustomerDetail'
import ServiceDet from './websitePro/pages/Service/ServiceDetail'
import AddOffer from './websitePro/pages/Offer/AddOffer'
import EditOffer from './websitePro/pages/Offer/EditOffer'
import EditService from './websitePro/pages/Service/EditService'
import AddService from './websitePro/pages/Service/AddService'
import LayoutAdmin from './admin/Layout'

// ADMIN ROUTES
import DashboardAdmin from './admin/page/Dashboard'
import Client from './admin/page/Client'
import DetailsClient from './admin/page/Client/Details'
import UpdateClient from './admin/page/Client/Update'
import ClientPro from './admin/page/ClientPro'
import DetailsClientPro from './admin/page/ClientPro/details'
import UpdateClientPro from './admin/page/ClientPro/Update'
import NewClientPro from './admin/page/ClientPro/New'
import AbonnementA from './admin/page/ClientPro/Abonnement'
import AbonnementAdmin from './admin/page/ClientPro/Abonnement'
import ProfileAdmin from './admin/page/Profile'
import ChangePassword from './admin/page/Profile/Generale/ChangePassword'
import ChangeEmail from './admin/page/Profile/Generale/ChangeEmail'
import VerificationEmail from './admin/page/Profile/Generale/VerificationEmail'
import UpdateProfile from './admin/page/Profile/Generale/UpdateProfile'
import NewClient from './admin/page/Client/New'
import ServiceAdmin from './admin/page/ClientPro/ServiceAdmin'
import DetailsAbonnement from './admin/page/ClientPro/Abonnement/Details'
import Notification from './admin/page/Notification'
import Offre from './admin/page/ClientPro/ServiceAdmin/Offre'
import DetailsService from './admin/page/ClientPro/ServiceAdmin/Details'
import Administrateur from './admin/page/Administrateur'
import NewAdministrateur from './admin/page/Administrateur/New'
import DetailsAdministrateur from './admin/page/Administrateur/Details'
import UpdateAdministrateur from './admin/page/Administrateur/Update'
import Categorie from './admin/page/Categorie'
import NewCategorie from './admin/page/Categorie/New'
import UpdateCategorie from './admin/page/Categorie/Update'
import UpdateService from './admin/page/ClientPro/ServiceAdmin/Update'
import { LayoutSecurity } from './website/layout/LayoutSecurity'
import ContactUs from "./website/pages/Contactus"
import ParentComponent from './website/pages/ParentComponent'
// import AddService from "./websitePro/pages/Service/AddService"

import Bookings from './website/pages/Booking/Bookings'
import ProtectedRoute from './security/ProtectedRoutes'
import PrivateRoute from "./security/PrivateRoute"

import ForgotPassword from "./auth/ForgotPassword"

export const Routers = () => {
  const isUserAuthenticated = localStorage.getItem("soro-id") !== null && localStorage.getItem("soro-email") !== null
  const isProUserAuthenticated = localStorage.getItem("soro-id") !== null && localStorage.getItem("soro-email") !== null && localStorage.getItem("soro-role") === "prestataire" 
  const isAdminAuthenticated = localStorage.getItem("soro-id") !== null && localStorage.getItem("soro-email") !== null && localStorage.getItem("soro-role") === "admin" 
  return (
    <BrowserRouter>
      <Routes>
         <Route path="/" element={<Layout isAuthenticated={isUserAuthenticated} />}>
          {/* Public routes */}

          <Route index element={<Home />} />
          <Route path="offer" element={<Offer />} />
          <Route path="service" element={<Service />} />
          <Route path="prestataire" element={<UserPro />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="service/:id" element={<ServiceDetail />} />
          <Route path='prestataire/:id' element={<ProfilPrestataire />} />


          {/* Private routes */}

          <Route element={<PrivateRoute isAuthenticated={isUserAuthenticated} />}>
            <Route path="booking/:id" element={<AddBooking />} />
            <Route path='reservations' element={<Booking />} />
            <Route path='reservation' element={<Bookings />} />
            <Route path='reservation/:id' element={<BookingDetail />} />
            <Route path='reservation/new' element={<AddBooking />} />
            <Route path='profil' element={<Profil />} />          
          </Route>

        
        </Route>
        {/* Private routes */}
        <Route element={<PrivateRoute isAuthenticated={isProUserAuthenticated} />}>
        <Route path="/pro/" element={<WebsiteProLayout />}>
          <Route index element={<Dashboard />} />
          <Route path='appointments' element={<Appointment />} />

          <Route path='customers' element={<Customer />} />
          <Route path='customer/:id' element={<CustomerDetail />} />
          <Route path='services' element={<ServicePro />} />
          <Route path='service/edit/:id' element={<EditService />} />

          <Route path='service/:id/offers' element={<Offer />} />
          <Route path='service/:id/offer/add' element={<AddOffer />} />
          <Route path='service/:id/offer/edit/:id1' element={<EditOffer />} />
 
          <Route path='service/edit/:id' element={<EditService />} /> 
          <Route path='service/:id' element={<ServiceDet />} />
          <Route path='service/add' element={<AddService />} />
          <Route path='service/availability/:id' element={<Availability />} />

          <Route path='parameter' element={<Parameter />} />
          <Route path='abonnement' element={<Abonnement />} />
          <Route path='parametre' element={<Profile />} />

        </Route>

        </Route>

        {/* ADMIN ROUTE */}
        <Route element={<PrivateRoute isAuthenticated={isAdminAuthenticated} />}>

        <Route path='/admin' element={<LayoutAdmin />}>
          <Route index element={<DashboardAdmin />} />

          {/* CLIENT ROUTE */}
          <Route path="client" element={<Client />} />
          <Route path="nouveauClient" element={<NewClient />} />
          <Route path="detailsClient/:id" element={<DetailsClient />} />
          <Route path="modificationClient/:id" element={<UpdateClient />} />

          {/* CLIENT PRO ROUTE */}
          <Route path="prestataire" element={<ClientPro />} />
          <Route path="nouveauPrestataire" element={<NewClientPro />} />
          <Route path="detailsPrestataire/:id" element={<DetailsClientPro />} />
          <Route path="modificationPrestataire/:id" element={<UpdateClientPro />} />

          {/* ADMINISTRATEUR ROUTE */}
          <Route path="administrateur" element={<Administrateur />} />
          <Route path="nouveauAdministrateur" element={<NewAdministrateur />} />
          <Route path="detailsAdministrateur/:id" element={<DetailsAdministrateur />} />
          <Route path="modificationAdministrateur/:id" element={<UpdateAdministrateur />} />

          {/* ABONNEMENT ROUTE */}
          <Route path="abonnement/:id" element={<AbonnementA />} />

          {/* ABONNEMENT ROUTE */}
          <Route path="abonnement/:id" element={<AbonnementAdmin />} />

          <Route path="detailsAbonnement/:id" element={<DetailsAbonnement />} />

          {/* SERVICE ROUTE */}
          <Route path="service/:id" element={<ServiceAdmin />} />
          <Route path="detailsService/:id" element={<DetailsService />} />
          <Route path="modificationService/:id" element={<UpdateService />} />
          {/* <Route path="nouveauService" element={<NewService />} /> */}
          <Route path="offre/:id" element={<Offre />} />

          {/* CATEGORIE ROUTE */}
          <Route path="secteur" element={<Categorie />} />
          <Route path="nouveauSecteur" element={<NewCategorie />} />
          <Route path="modificationSecteur/:id" element={<UpdateCategorie />} />

          {/* PROFILE ROUTE  */}

          <Route path="profile" element={<ProfileAdmin />} />
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="changeEmail" element={<ChangeEmail />} />
          <Route path="verificationEmail/:email" element={<VerificationEmail />} />
          <Route path="modificationProfile" element={<UpdateProfile />} />
          <Route path="notification" element={<Notification />} />
        </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path='/test' element={ <ParentComponent/>} />


      </Routes>
    </BrowserRouter>
  )
}


export default Routers