import React from 'react'
import { api } from '../../../api';

const ModalAbonnement = ( {show, onClose, subscription}) => {

    if (!show) return null;

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
    };
    
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-950/20">
    <div className="relative w-[900px] px-4 h-full md:h-auto ">
       

      <div className="bg-white rounded-lg shadow flex">
         <div className="bg-neutral-200 w-[300px] h- rounded-l-lg"></div>
         <div className='w-full pb-10'> 
            
            <div className="flex items-start justify-between px-4 pt-4 pb-2 w-full rounded-t">
          <h3 className="text-gray-900 text-lg lg:text-lg font-semibold ">
           Information générale
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
            </div>
            {/* <p className='text-sm font-medium text-gray-1 ml-4 mb-4'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, </p> */}
          <div className="bg-primary px-4 py-2 text-white font-semibold">Plan d’abonnement</div>
            <table className='table1'>
                <tbody>
                    <tr>
                        <td>Type d'abonnement</td>
                        <td>{subscription.type}</td>
                    </tr>
                    <tr>
                        <td>Prix (Ar)</td>
                        <td>{subscription.prix}</td>
                    </tr>
                    <tr>
                        <td>Date d’activation</td>
                        <td>{formatDate(subscription.dateDebut)}</td>
                    </tr>
                    <tr>
                        <td>Référence</td>
                        <td>{subscription.ref}</td>
                    </tr>
                    <tr>
                        <td>Référence</td>
                        <td>{subscription.ref}</td>
                    </tr>
                    <tr>
                      <td>Image</td>
                      <td>
                      <div className="flex gap-1 items-center">
                        <img src={api + '/storage/' + subscription.image} className='w-10 h-10 rounded-md'/>
                      </div>
                    </td>
                    </tr>
                </tbody>
            </table></div>
       

            </div>
        </div>
        </div>
  )
}

export default ModalAbonnement